<template>
  <div>
    <app-header />

    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'public.home' }">Home</router-link>
              </li>
              <li><span>Recover Account</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        uk-section uk-section-muted uk-flex uk-flex-middle uk-animation-fade
      "
      style="padding-top; 0"
      uk-height-viewport
    >
      <div class="uk-width-1-1" style="margin-top: -100px">
        <div class="uk-container">
          <div class="uk-grid-margin uk-grid uk-grid-stack" uk-grid>
            <div class="uk-width-1-1@m" v-if="token_is_valid">
              <div
                class="
                  uk-margin
                  uk-width-large
                  uk-margin-auto
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-box-shadow-large
                "
              >
                <h3 class="uk-card-title uk-text-center">Recover Your Account</h3>
                <div class="uk-alert-danger" uk-alert v-if="has_error">
                  <a class="uk-alert-close" uk-close href="#"></a>
                  <p>Invalid Password</p>
                </div>
                <form @submit.prevent="submit">
                  <div class="uk-margin">
                    <label for="">Set a new password.</label>
                    <div class="uk-inline uk-width-1-1">
                      <span class="uk-form-icon"
                        ><i
                          class="fa fa-lock"
                          style="margin-right: 20px"
                        ></i
                      ></span>
                      <input
                        class="uk-input uk-form-large"
                        type="password"
                        required
                        v-model="user.password"
                        placeholder="New Password"
                      />
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label for="">Confirm your new password</label>
                    <div class="uk-inline uk-width-1-1">
                      <span class="uk-form-icon"
                        ><i
                          class="fa fa-lock"
                          style="margin-right: 20px"
                        ></i
                      ></span>
                      <input
                        class="uk-input uk-form-large"
                        type="password"
                        placeholder="Confirm Password"
                        required
                        v-model="user.password_confirmation"
                      />
                    </div>
                  </div>
                  <div class="uk-margin">
                    <button
                      class="
                        uk-button uk-button-primary uk-button-large uk-width-1-1
                      "
                    >
                      Recover My Account
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="uk-width-1-1@m" v-if="! token_is_valid">
              <div
                class="
                  uk-margin
                  uk-width-large
                  uk-margin-auto
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-box-shadow-large
                "
              >
                <h2>Failed!</h2>
                <p>Invalid Token. Contact us for further assistance</p>
                <p class="text-center">
                  <router-link :to="{ name: 'public.home' }" class="uk-button uk-button-primary uk-button-large uk-width-1-1"><i class="fa fa-home"></i> Okay</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      token_is_valid: true,
      has_error: false
    };
  },

  methods: {
    fetch() {
      this.$loader.start('public')
      this.$axios.post('/api/v1/check-account', { token: this.$route.params.code }).then(response => {
        if(response.data.status) {
          this.token_is_valid = true
        } else {
          this.token_is_valid = false
        }

        this.$loader.stop()
      })
    },

    submit() {
      this.has_error = false
      let data = this.user
      data.token = this.$route.params.code
      this.$loader.start('public')

      this.$axios.post('/api/v1/recover-account', data).then(response => {
        this.$auth.setToken(response.data.access_token);
        window.location.href = this.$router.resolve({
          name: "redirect",
        }).href;
      }).catch(() => {
        this.$loader.stop()
        this.has_error = true
      })
    },
  },
};
</script>
